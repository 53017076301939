.header {
  position: relative;
  padding: 10rem 8rem;
  display: flex;
  align-items: flex-end;
  background-color: var(--color-background);
  z-index: 1;
}

.header__title {
  width: 500px;
  font-weight: 300;
  font-size: 88px;
  color: #151c26;
  font-family: var(--font-family-main);
  margin-right: 1rem;
}

.header__cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  justify-content: center;
  z-index: 1;
}

.header__card {
  margin-right: 2rem;
}

.header__card--2 {
  transform: translateY(-60px);
}

.header__card--3 {
  margin-top: 2rem;
}

.header__card-img {
  width: 100%;
}

.header__image {
  position: absolute;
  bottom: 0px;
  right: 150px;
  z-index: 0;
}

@media screen and (max-width: 1500px) {
  .header {
    padding: 10rem 2rem;
  }

  .header__image {
    right: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .header__title {
    font-size: 70px;
    width: 375px;
  }

  .header__image {
    right: 0px;
  }
}

@media screen and (max-width: 1000px) {
  .header {
    flex-direction: column;
    padding: 4rem 1rem;
    align-items: center;
  }

  .header__title {
    margin-bottom: 8rem;
  }

  .header__image {
    width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .header {
    flex-direction: column;
    padding: 4rem 1rem;
    align-items: center;
  }

  .header__title {
    font-size: 50px;
    margin-bottom: 5rem;
  }

  .header__card {
    width: 150px;
  }

  .header__image {
    width: 250px;
  }
}

@media screen and (max-width: 550px) {
  .header {
    padding: 2rem 1rem;
  }

  .header__title {
    font-size: 30px;
    width: 200px;
  }

  .header__cards-container {
    width: 300px;
  }
  .header__card {
    width: 100px;
  }

  .header__image {
    width: 150px;
  }

  .header__card {
    margin-right: 1rem;
  }

  .header__card--3 {
    margin-top: 1rem;
  }
}
